import { Controller } from "stimulus"
import cookies from "js-cookie"

export default class extends Controller {

  connect() {
    if (window.innerWidth < 992 &&
        document.querySelectorAll('.sidebar.active').length > 0 &&
        !document.body.classList.contains('stopscroll')
    ) {
      this.toggle(document.body, "stopscroll")
      this.toggle(document.querySelector("#main-interface"), 'active')
    }
  }

  toggleLeft(){

    var sbleft = document.querySelector(".sidebar-left")
    this.toggle(sbleft, "active")
    var cont = document.querySelector("#content-container")
    this.toggle(cont, "with-sidebar-left")
    var main = document.querySelector("#main-interface")
    this.toggle(main, "active")
    if(window.innerWidth < 992){
        this.toggle(document.body,"stopscroll")
    }
    var sright = document.querySelector(".sidebar-right")
    if(sright.classList.contains("active") && (window.innerWidth < 992)) {
        this.toggle(sright, "active")
        this.toggle(cont, "with-sidebar-right")
        this.toggle(main, "active")
        this.toggle(document.body,"stopscroll")
    }
  }

  toggleRight(){
        var sr = document.querySelector("#sidebarcontrollerdiv")
        var sidebar = this.application.getControllerForElementAndIdentifier(sr, 'sidebar')

        var sright = document.querySelector(".sidebar-right")
        this.toggle(sright, "active")
        var cont = document.querySelector("#content-container")
        this.toggle(cont, "with-sidebar-right")
        var main = document.querySelector("#main-interface")
        this.toggle(main, "active")
        if(sright.classList.contains("active"))
        {

          if(!sidebar.mineTarget.querySelector("li"))
          {
            sidebar.update()
          }
          sidebar.updateAges()
          sidebar.startTimer()
        }
        else
        {
          sidebar.stopTimer()
        }
        if(window.innerWidth < 992){
            this.toggle(document.body,"stopscroll")
        }
        var sleft = document.querySelector(".sidebar-left")
        if(sleft.classList.contains("active") && (window.innerWidth < 992)) {
            this.toggle(sleft, "active")
            this.toggle(cont, "with-sidebar-left")
            this.toggle(main, "active")
            this.toggle(document.body,"stopscroll")
        }

  }

  toggle(elem, klass){

    if(elem.classList.contains(klass))
    {
      elem.classList.remove(klass)
    }
    else
    {
      elem.classList.add(klass)
    }
    if (elem.classList.contains('sidebar')) this.setCookie()
  }

  setCookie() {
    var open_sidebars = []
    if (document.querySelector(".sidebar-left").classList.contains('active')) open_sidebars.push('left')
    if (document.querySelector(".sidebar-right").classList.contains('active')) open_sidebars.push('right')
    //console.log(open_sidebars)
    var text

    switch(open_sidebars.length) {
      case 0:
        text = 'none'
        break;
      case 1:
        text = open_sidebars[0]
        break;
      case 2:
        text = 'both'
    }

    cookies.set('open_sidebars', text, {sameSite: 'strict'})
  }

}
