import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = ["export", "organisation", "month", "year", "invFrom", "invTo", "dateFrom", "dateTo", "filter"]
  
  checkExportButton() {
    if (
      this.organisationTarget.value != '' ||
      (this.monthTarget.value != '' && this.yearTarget.value != '') ||
      (this.invFromTarget.value != '' && this.invToTarget.value != '') ||
      (this.dateFromTarget.value != '' && this.dateToTarget.value != '')
    ) {
      this.exportTarget.removeAttribute('disabled')
    }
    else if (!this.exportTarget.hasAttribute('disabled')) {
      this.exportTarget.setAttribute('disabled', 'true')
    }
  }

  setRequired() {
    var monthVal = this.monthTarget.value
    var yearVal  = this.yearTarget.value
    if ((monthVal == '' && yearVal != '') || (monthVal != '' && yearVal == '')) {
      [this.monthTarget, this.yearTarget].forEach(el => {
        el.insertAdjacentHTML('afterend', '<div class="invalid-feedback">is required</div>')
        if(el.value == '') el.classList.add('is-invalid')
      })
      this.filterTarget.setAttribute('disabled', 'true')
    }
    else {
      [this.monthTarget, this.yearTarget].forEach(el => {
        if (el.nextElementSibling != null) el.nextElementSibling.remove()
        el.classList.remove('is-invalid')
      })
      this.filterTarget.removeAttribute('disabled')
    }
  }
  
  connect() {
    this.checkExportButton();
    this.setRequired();
  }
  
}
