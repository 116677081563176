import { Controller } from "stimulus"
import autocomplete from 'autocomplete.js'
import axios from 'axios'

export default class extends Controller {

  static targets = ['autocomplete', 'list', 'emailField']

  connect() {
    let that = this;

    autocomplete('#'+that.autocompleteTarget.id, {
      hint: false,
      minLength: 3,
      autoWidth: false,
      clearOnSelected: (that.data.get('type') == 'contact_name_email')
    }, [{
      displayKey: 'name',
      source: (query, populateResults) => {
        let url = that.getUrl()
        axios.get(url, {
          params: {
            query: query
          }
        }).then(response => {
          if (response.data.length === 0)
          {
            this.turnRed()
          }
          else
          {
            this.turnBlue()
            populateResults(response.data)
          }

        })
      }
    }]).on('autocomplete:selected', (ev, suggestion, dataset, context) => {
      that.turnBlue()
      if (that.data.get('hiddenId') != null) {
        document.getElementById(this.data.get('hiddenId')).value = suggestion.id
        var changeEvent = new Event('change')
        document.getElementById(this.data.get('hiddenId')).dispatchEvent(changeEvent)
      }
      if (suggestion.email != undefined) {
        that.addEmail(suggestion)

      }
    })
    this.autocompleteTarget.autocomplete ="off" // hack to trick chromium into behaving nicely
  }

  turnRed(){
    this.autocompleteTarget.style.color="red"
    this.autocompleteTarget.style.border="2px solid red"
  }

  turnBlue(){
    this.autocompleteTarget.style.color="#212529"
    this.autocompleteTarget.style.border="1px solid #ced4da"
  }

  getUrl() {
    let type = this.data.get('type');
    switch (type) {
      case 'org_name':
        return '/search/org_name'
        break;
      case 'contact_name':
        return '/search/contact_name'
        break;
      case 'contact_name_email':
        return '/search/contact_name_email'
        break;
    }
  }



  addEmail(suggestion) {
    // Add email to visible list
    this.addNameToList(suggestion)
    // Add email to hidden field
    var newActivity = this.emailFieldTarget.getAttribute('data-newactivity')
    if(newActivity == 'true'){
        console.log("add to array")
        this.addEmailToNewActivityField(suggestion.email)
    } else {
        console.log("add to text area")
        this.addEmailToField(suggestion.email)
    }
  }

  addNameToList(suggestion) {
    var main_email = suggestion.email.split(',')[0]
    var link   = document.createElement('a')
    link.href  = '#'
    link.id = main_email + "_link"
    link.title = "Click to remove '"+main_email+"'"
    link.setAttribute('data-toggle', 'tooltip')
    link.name  = main_email
    var newActivity = this.emailFieldTarget.getAttribute('data-newactivity')
    if(newActivity == 'true'){
        link.classList.add('mr-2', 'clickable')
        link.setAttribute("data-email", main_email)
        link.setAttribute("data-name", suggestion.full_name)
        link.setAttribute("data-action", "click->activity#removeEmail")
    }  else {
        link.classList.add('mr-2', 'remove_outbound_mail')
    }
    var text = document.createTextNode(suggestion.full_name)
    link.appendChild(text)
    if (this.listTarget.childElementCount == 0) this.listTarget.innerHTML = ''
    this.listTarget.append(link)
  }

  addEmailToField(email) {
    var email_to_add = email.split(',')[0]
    var new_val = ''
    var field_val = this.emailFieldTarget.value
    if (field_val == '') {
      new_val = email_to_add
    }
    else {
      new_val = field_val + ',' + email_to_add
    }
    this.emailFieldTarget.value = new_val
  }

  addEmailToNewActivityField(email_to_add) {
      console.log('autocomplete add email to field')
      var input = document.createElement('input')
      input.name = "activity[email_to_list][]"
      input.type = "hidden"
      input.value = email_to_add
      this.emailFieldTarget.appendChild(input)
  }

}
