import { Controller } from "stimulus"

import axios from 'axios'

export default class extends Controller {

  static targets = [
    "submitButton", "resolution", "timespent","timeagreed",
    "occurred", "officeHours","officeMinutes", "officeDays",
    "onsiteHours","onsiteMinutes","onsiteDays","onsite",
    "days", "hours", "minutes", "documents","closeDocuments",
    "emailbox", "le", "accman", "maincontacts", "list", "emailfield"
    ]

  connect(){
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers['Accept'] = 'application/json';

    let that=this

    this.editorid = this.data.get("actid")

    this.id = ("#cke_" + this.editorid)
}

enableSubmit(e) {
       this.submitButtonTarget.disabled = false
       if(e.target.value == "true")
       {
         this.resolutionTarget.classList.remove("alert-danger");
         this.resolutionTarget.classList.add("alert-success");
       }
       else
       {
         this.resolutionTarget.classList.remove("alert-success");
         this.resolutionTarget.classList.add("alert-danger");
       }

 }

 toggleEmail(e){
   let emails = this.maincontactsTarget.getAttribute('data-emails').split(',')
   let names = this.maincontactsTarget.getAttribute('data-names').split(',')
   if (this.maincontactsTarget.checked) {
       for (var i = 0; i < emails.length; i++) {
           this.addEmailToList(emails[i], names[i], false)
       }
   } else {
       for (var i = 0; i < emails.length; i++) {
           this.removeEmailFromList(emails[i], names[i])
       }
   }

   if(this.visible()){
       this.showContacts()
   } else {
       this.hideContacts()
   }
 }

 showContacts(){
     if(!this.emailboxTarget.classList.contains('d-block')) this.emailboxTarget.classList.add('d-block')
     this.emailboxTarget.classList.remove("d-none")
 }

 hideContacts(){
     if(!this.emailboxTarget.classList.contains('d-none')) this.emailboxTarget.classList.add('d-none')
     this.emailboxTarget.classList.remove("d-block")
 }

 toggleLeadEngEmail(e){
     let email = this.leTarget.getAttribute('data-email')
     let name = this.leTarget.getAttribute('data-name')
     if (this.leTarget.checked) {
         this.addEmailToList(email, name, true)
     } else {
         this.removeEmailFromList(email, name)
     }

     if(this.visible()){
         this.showContacts()
     } else {
         this.hideContacts()
     }

 }

 toggleAccManEmail(e){
     let email = this.accmanTarget.getAttribute('data-email')
     let name = this.accmanTarget.getAttribute('data-name')
     if (this.accmanTarget.checked) {
         this.addEmailToList(email, name, true)
     } else {
         this.removeEmailFromList(email, name)
     }

     if(this.visible()){
         this.showContacts()
     } else {
         this.hideContacts()
     }
 }

 addEmailToList(email, name, unclickable){
    if(this.emailNotPresent(email)){
         this.addNameToList(email, name, unclickable)
         this.addHiddenEmailField(email)
     }
 }

 addNameToList(email, name, unclickable) {
     var main_email = email
     var link   = document.createElement('a')
     link.href  = '#'
     link.id = email + "_link"
     link.rel   = 'tooltip'
     link.name  = main_email
     if(unclickable){
         link.title = "Unselect relevant email option to remove '"+main_email+"'"
         link.classList.add('mr-2', 'unclickable')
     } else {
         link.title = "Click to remove '"+main_email+"'"
         link.setAttribute("data-email", main_email)
         link.setAttribute("data-name", name)
         link.setAttribute("data-action", "click->activity#removeEmail")
         link.classList.add('mr-2', 'clickable')
     }
     var text = document.createTextNode(name)
     link.appendChild(text)
     if (this.listTarget.childElementCount == 0) this.listTarget.innerHTML = ''
     this.listTarget.append(link)
 }

 addHiddenEmailField(email_to_add) {
     var input = document.createElement('input')
     input.name = "activity[email_to_list][]"
     input.type = "hidden"
     input.value = email_to_add
     this.emailfieldTarget.appendChild(input)
 }

 removeHiddenEmailField(email_to_remove) {
     for (let i = 0; i < this.emailfieldTarget.children.length; i++) {
         if(this.emailfieldTarget.children[i].value === email_to_remove){
             this.emailfieldTarget.removeChild(this.emailfieldTarget.children[i])
         }
     }
 }

 removeEmail(event){
     event.preventDefault()
     let link = event.target
     let email = link.getAttribute('data-email')
     let name = link.getAttribute('data-name')
     this.removeEmailFromList(email, name)
     if(!this.visible()){
         this.hideContacts()
     }
     document.querySelector('.tooltip').remove()
     let mainContacts = this.maincontactsTarget.getAttribute('data-emails').split(',')
     let uncheck = true
     for (var i = 0; i < mainContacts.length; i++) {
         if(this.emailNotPresent(mainContacts[i]) === false){
             uncheck = false
         }
     }
     if(uncheck){
         console.log('uncheck box')
         this.maincontactsTarget.checked = false
     }
 }


 removeEmailFromList(email, name){
     var element = document.getElementById(`${email}_link`);
     if(element){
         element.remove()
         this.removeHiddenEmailField(email)
     }
 }

 emailNotPresent(email){
     var result = true
     for (let i = 0; i < this.emailfieldTarget.children.length; i++) {
         if(this.emailfieldTarget.children[i].value === email){
             result = false
         }
     }
     return result
 }

 visible(){
     return this.emailfieldTarget.childElementCount > 0
 }


 finaliseTimes(){
   let that = this
   let ticketid = this.data.get("ticketid")
   let agreed = this.timeagreedTarget.value
   let onsite = this.onsiteTarget.checked
   if (agreed == null || agreed == undefined || agreed =='')
   {
     agreed = 0
   }
   axios.post('/tickets/get_final_times', {
       hidden_ticket_id: ticketid,
       time_agreed: agreed,
       occurred_onsite: onsite
     }).then(response => {
     that.updateFinalTimes(response.data)
     that.updateSummary(response.data)
   })
 }

 updateFinalTimes(data){
   this.officeDaysTarget.innerHTML = data.office_days
   this.officeHoursTarget.innerHTML = data.office_hours
   this.officeMinutesTarget.innerHTML = data.office_minutes
   this.onsiteDaysTarget.innerHTML = data.onsite_days
   this.onsiteHoursTarget.innerHTML = data.onsite_hours
   this.onsiteMinutesTarget.innerHTML = data.onsite_minutes
   this.daysTarget.value = data.total_days
   this.hoursTarget.value = data.total_hours
   this.minutesTarget.value = data.total_minutes
 }

 updateSummary(data){
   let spent = document.getElementById("summary_spent")
   let agreed = document.getElementById("summary_agreed")
   let projected = document.getElementById("summary_projected")
   spent.innerHTML = (data.total_text_spent)
   agreed.innerHTML = (data.total_text_agreed)
   projected.innerHTML = (data.total_text_projected)
 }

 copyToAgreed(){
   this.timeagreedTarget.value = this.timespentTarget.value
 }

 showAttachments(){

   let atts = this.documentsTarget
   let uppy = atts.querySelector("button.uppy-Root")
   if(atts.style.display === "none")
   {
     atts.style.display = "block"
     if(uppy === null)
     {
       uploader.init("new-attachments");
     }

   }
   else
   {
      atts.style.display = "none"
   }
 }

 showCloseAttachments(){
   let atts = this.closeDocumentsTarget
   let uppy = atts.querySelector("button.uppy-Root")
   if(atts.style.display === "none")
   {
     atts.style.display = "block"
     if(uppy === null)
     {
        uploader.init("close-attachments");
     }
   }
   else
   {
      atts.style.display = "none"
   }
 }

 updateTimes(){
   let that = this
   axios.post('/tickets/get_final_times', {
       hidden_ticket_id: this.data.get("ticketid"),
       time_agreed: 0,
       occurred_onsite: false
     }).then(response => {
     that.updateFinalTimes(response.data)
     that.updateSummary(response.data)
   })
 }

 checkForFinal(e){
   if (e.target.value=="Closed")
   {
     this.updateTimes()
   }
 }


}
