import { Controller } from "stimulus"

import axios from 'axios'



export default class extends Controller {
  static targets = [ "cardtext", "code" ]

  connect() {
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.post['Content-Type'] = 'application/json'
    axios.defaults.headers['Accept'] = 'application/json'
    console.log("twofactor controller connected")
  }

  enable(e){
    e.preventDefault()
    e.stopPropagation()
    var cid = this.data.get("contactid")
    var that = this
    axios("/contacts/" + cid + "/user/enable_two_factor", {
      method: "PATCH"
    }).then(function(json){
       that.cardtextTarget.innerHTML = json.data.html
    }

    )
  }

  confirm(e){    
    e.stopPropagation()
    if (e.keyCode && (e.keyCode != 13 ))
    {
      return
    }

    var cid = this.data.get("contactid")
    var that = this
    axios("/contacts/" + cid + "/user/confirm_two_factor", {
      method: "PATCH",
      data:{
        user: {
          otp_attempt: this.codeTarget.value
        }
      }
    }).then(function(json){
       that.cardtextTarget.innerHTML = json.data.html
    })
  }

  disable(e){
    e.preventDefault()
    e.stopPropagation()
    var cid = this.data.get("contactid")
    var that = this
    var res = confirm("Are you sure you want to disable two-factor?")
    console.log(res)
    if (res)
    {
      axios("/contacts/" + cid + "/user/disable_two_factor", {
        method: "PATCH"
      }).then(function(json){
         that.cardtextTarget.innerHTML = json.data.html
      })
    }
  }

  resetBackupCodes(e){
    e.preventDefault()
    e.stopPropagation()
    var cid = this.data.get("contactid")
    var that = this
    axios("/contacts/" + cid + "/user/backup_codes", {
      method: "GET"
    }).then(function(json){
       that.cardtextTarget.innerHTML = json.data.html
    })
  }




}
