import { Controller } from "stimulus"
import dragula from 'dragula'
import axios from 'axios'


export default class extends Controller {

  connect() {
    var notes
    var that = this
    dragula([this.element], {
      moves: function (el, container, handle) {
        return handle.classList.contains('dragHandle');
      }
    }).on('drag', el => {
    }).on('drop', el => {
      // Hide any tooltips that popped up
      document.querySelectorAll('.tooltip').forEach(t => t.remove())
      
      var rows = [...that.element.rows].filter(x => x.classList.contains('itemrow'))

      // move note rows to their correct positions
      var new_rows = []
      var note_row
      rows.forEach(el => {
        new_rows.push(el)
        if (note_row = document.getElementById(el.id+"_notes_generating")) new_rows.push(note_row)
      })
      that.element.inner_html = ''
      new_rows.forEach(r => that.element.insertAdjacentElement('beforeend', r))

      // Update row positions in DB
      var key = that.element.parentElement.id
      var values = rows.map(r => r.id.substring(3))
      var post_data = {}
      post_data[key] = values

      axios.post('/opportunities/sort_line_items', post_data).then(resp => {
        if (resp.data.email_text) {
          document.getElementById('mail_link').href = resp.data.email_text
        }
      })
    })
  }

}
