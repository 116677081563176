import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ["search", "filterable"]



    filter(event){ 


        let lowerCaseFilterTerm = this.searchTarget.value.toLowerCase()
        this.filterableTargets.forEach((el, i) => {
            let filterableKey =  el.getAttribute("data-filter-key")
            el.classList.toggle("d-none", !filterableKey.includes( lowerCaseFilterTerm ) )
        })

        this.updateTabCounts()
    }

    /**
    change(event){
        console.log(this.searchTarget.value.length)
        let selectedOption = event.target.selectedOptions[0].value
        let myTickets = document.getElementById('my-tickets')
        myTickets.querySelectorAll('tr').forEach((row, i) => {
            if(selectedOption === ""){
                row.classList.remove("filter--notFound")
            } else if(i > 0 && !row.classList.contains(selectedOption)){
                row.classList.add("filter--notFound")
            } else {
                row.classList.remove("filter--notFound")
            }
        })
        this.updateTabCounts()
    }
    */

    updateTabCounts(){
        this.updateMyTicketCount()
        this.updateUnassignedTicketCount()
        this.updateOpportunityCount()
    }

    updateMyTicketCount() {
        let ticketCount = this.tableCount('my-tickets');
        let taskCount = this.tableCount('my-tasks');
        document.getElementById('tickets-tab').textContent = ticketCount + taskCount
    }

    updateUnassignedTicketCount() {
        let unassignedCount = this.tableCount('unassigned-tickets');
        let dueCount = this.tableCount('unassigned-due-tickets');
        document.getElementById('unassigned_tickets-tab').textContent = unassignedCount + dueCount
    }

    updateOpportunityCount() {
        document.getElementById('opportunities-tab').textContent = this.tableCount('table_opportunities')
    }

    tableCount(table_id) {
        let table = document.getElementById(table_id)
        let visibleCount = 0
        if(table){
            let totalCount = table.rows.length
            let hiddenRows = table.querySelectorAll('.d-none').length
            visibleCount = totalCount - 1 - hiddenRows
        }
        return visibleCount
    }

}
