import consumer from "./consumer"
import moment from "moment"

consumer.subscriptions.create("StatusChannel", {
  connected() {
    console.log("cable connected");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    if(data.status && data.status != 'undefined')
    {
        this.updateStatus(data)
    }
    if(data.off_stack_id)
    {
        this.removeFromUnassigned(data)
    }
  },

  updateStatus(data){
    let st = document.getElementById("msg-" + data.contact_id);
    //broadcast includes the person updating their status - just don't do anything if so
    if(st && st.parentNode.id === 'my-status')
    {
      return
    }
    let todays = document.getElementById("todays-statuses");
    if (st)
    {
      st.remove();
    }
    todays.innerHTML= data.status + todays.innerHTML
  },

  removeFromUnassigned(data){
    var tr = document.querySelector("table.unassigned tbody tr#ticket-row-" + data.off_stack_id)
    if(tr)
    {
      tr.classList.add("highlighting")
      setTimeout(function(){
        tr.remove()
      }, 3000)

    }
    var tid = document.getElementById("ticket-id-span")
    if(tid)
    {
      if(tid.dataset.ticketId === data.off_stack_id)
      {
        if(!window.isUpdating){
          bootbox.alert("Please note: this ticket has just been assigned to " + data.owner + ", it is no longer unassigned")
        }
        let s = document.getElementById("ticket_owner_id")
        let old = s.querySelector("option[selected]")
        if(old)
        {
          old.removeAttribute("selected")
        }
        let str = "option[value='" + data.owner_id + "']"
        let n = s.querySelector(str)
        if(n)
        {
          n.setAttribute("selected","selected")
        }
      }
    }
  }
});
