import { Controller } from "stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = [ "source","orgid","select1", "select2","wpdiv","contdiv", "owner" ]

  connect(){
    if(this.sourceTarget.value)
    {
      this.openSelects()
    }
  }

  disableOwner(){
    if(this.orgidTarget.value === "")
    {
      this.ownerTarget.disabled = false
      return
    }
    if(this.ownerTarget.disabled){return}
    this.ownerTarget.disabled = true
  }

  enableOwner(){    
    this.ownerTarget.disabled = false
  }

  replaceOptions(e){
    this.disableOwner()
    if(this.orgidTarget.value === "")
    {
      this.wpdivTarget.classList.add("d-none")
      this.contdivTarget.classList.add("d-none")
      this.sourceTarget.value=""
      this.enableOwner()
      return true
    }
    else
    {
      this.openSelects()
    }


    let wpUrlString = this.data.get("wpurl")
    let contUrlString = this.data.get("conturl")
    let that=this

    let wpurl = wpUrlString.replace(":id:", this.sourceTarget.value)
    let conturl = contUrlString.replace(":id:", this.sourceTarget.value)
    axios.get(wpurl).then(
      function(response){
        that.setOptions(that.select1Target, response.data.wps)
      }
    )
    axios.get(conturl).then(
      function(response){
        that.setOptions(that.select2Target, response.data)
        that.enableOwner()
      }
    )
  }

  openSelects(){
    this.wpdivTarget.classList.remove("d-none")
    this.contdivTarget.classList.remove("d-none")
  }

  setOptions(element, list){
    let optList = ["<option value=''></option>"]
    list.forEach(function(item){
      optList.push(`<option value=${item.id}>${item.name}</option>`)
    })
    element.innerHTML = optList.join()
  }
}
