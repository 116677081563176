import { Controller } from "stimulus"

import axios from 'axios'



export default class extends Controller {

  static targets = ['low','medium', 'high', 'custom']


  connect(){
    var count = parseInt(this.data.get("count"))

    if(count < 20)
    {
      this.lowTarget.classList.add("active")
    }
    else
    {
      if(count < 30)
      {
        this.mediumTarget.classList.add("active")
      }
      else
      {
        this.highTarget.classList.add("active")
      }
    }

      this.customTarget.value = count
  }

   change(ev){
     var priority = ev.target.dataset['status']

     if(ev.target.classList.contains("active"))
     {
       return
     }
     var ok = confirm("Are you certain you want to change this tickets priority?")
     if(!ok)
     {
       this.customTarget.value = this.data.get("count")
       return
     }
     var cus_priority_val = null
     if(priority === 'custom')
     {
         cus_priority_val = this.customTarget.value
     }
     var ticketid = this.data.get("ticketid")
     var that = this
     axios("/tickets/setpriority",{
       method:"PUT",
       data: {
         ticketid: ticketid,
         status: ev.target.dataset['status'],
         priority: cus_priority_val
       }
     }).then(function(json){
       if(json.data.error)
       {
         alert(json.data.error)
       }
       else
       {
          that.clearActive()
          if(priority === 'custom')
          {
              if(cus_priority_val < 20)
              {
                  priority = 'low'
              }
              else
              {
                  if(cus_priority_val < 30)
                  {
                      priority = 'medium'
                  }
                  else
                  {
                      priority = 'high'
                  }
              }
          }
          that[priority+"Target"].classList.add("active")
          var pbox = document.getElementById("priority_score_holder")
          pbox.classList.remove("low","high","medium")
          pbox.classList.add(priority)
          var number =  document.getElementById("priority_score_number")
          var txt =  document.getElementById("priority_score_text")
          number.innerHTML=json.data.count
          that.customTarget.value = json.data.count
          txt.innerHTML = json.data.text
          that.data.set("count",json.data.count)
       }
     })
   }

   clearActive(){
     //this.lowTarget.blur()
     //this.mediumTarget.blur()
     //this.highTarget.blur()
     this.lowTarget.classList.remove("active")
     this.mediumTarget.classList.remove("active")
     this.highTarget.classList.remove("active")
   }


}
