import { Controller } from "stimulus"


export default class extends Controller {

  widthCheck(){
    console.log("width checked")
    let box = this.element
    let width = box.offsetWidth;
    let widthCheck = box.dataset.minWidth

    if (width > widthCheck) {
      
        document.querySelectorAll('#comments_main .comments_info').forEach(el => {
             el.classList.add('show')
         })
        document.querySelectorAll('.view-info .toggle .fa').forEach(el => {
            el.classList.remove('fa-plus')
            el.classList.add('fa-minus')
        })
        document.querySelectorAll('.view-info .toggle').forEach((el) => {
                el.setAttribute('data-original-title', 'Hide comment info')

        })
    }
    else
    {

      document.querySelectorAll('#comments_main .comments_info').forEach(el => {
           el.classList.remove("show")

       })
      document.querySelectorAll('.view-info .toggle .fa').forEach(el => {
          el.classList.add('fa-plus')
          el.classList.remove('fa-minus')
      })
      document.querySelectorAll('.view-info .toggle').forEach((el) => {
              el.setAttribute('data-original-title', '')

      })
    }
  }
}
