import { Controller } from "stimulus"


export default class extends Controller {
    static targets = [ "toggler", "tooltipContent" ]

    toggle() {
       
        this.togglerTargets.forEach((el) => {
            if (el.classList.contains('fa-plus')) {
                el.classList.remove('fa-plus');
                el.classList.add('fa-minus');
            } else {
                el.classList.remove('fa-minus');
                el.classList.add('fa-plus');
            }
        })

        this.tooltipContentTargets.forEach((el) => {
            if(el.getAttribute('data-original-title') == '') {
                el.setAttribute('data-original-title', 'Hide comment info')
            }else{
                el.setAttribute('data-original-title', '')
            }
        })

    }
}