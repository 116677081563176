import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["remove"]

  connect(){
    let that=this
    if(this.hasRemoveTarget)
    {
        setTimeout(function(){that.removeTarget.remove()}, parseInt(this.data.get("after")))
    }

  }
}
