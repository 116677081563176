import { Controller } from 'stimulus';

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default class extends Controller {
    static targets = ['form'];

    submitForm(event) {
        let msg = this.formTarget.getAttribute('data-message')
        this.formTarget.querySelector('input[name="commit"]').blur()
        if(msg){
            event.preventDefault()
            let self = this
            Swal.fire({
                title: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
            }).then(result => self.confirmed(result))
        }
    }

    confirmed(result){
        if(result.value){
            this.formTarget.removeAttribute('data-message')
            this.formTarget.submit()
        } else {
            this.formTarget.querySelector('input[name="commit"]').removeAttribute('disabled')
        }
    }

}