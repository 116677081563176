import ConfirmLinkController from "./confirmlink_controller"
import Swal from 'sweetalert2'

export default class extends ConfirmLinkController {

    ensureCheckboxSelected(event){
        let msg = this.linkTarget.getAttribute('data-message')

        if(msg){
            let something_checked = false;

            document.querySelectorAll(".email_several").forEach(function(email) {
                if(email.checked){
                    something_checked = true
                }
            });

            if (something_checked) {
                this.linkClicked(event)
            } else {
                this.linkTarget.blur()
                event.preventDefault()
                event.stopImmediatePropagation()
                Swal.fire('You must select at least one invoice to email!');
                return false;
            }
        }

    }

}