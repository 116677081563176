import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["checkboxes", "hiddenField", "submit"]
  static values = { required: Number }

  connect(){
    if (this.data.has('required')) this.update_hidden_field
  }

  check_all(e) {
    e.preventDefault();
    let checkboxes = this.checkboxesTargets
    let checked    = checkboxes.filter(x => x.checked)

    if (checked.length == checkboxes.length) {
      checkboxes.forEach(ch => { ch.checked = false })
    }
    else {
      checkboxes.forEach(ch => { ch.checked = true })
    }
  }

  update_hidden_field(e) {
    if (this.hasHiddenFieldTarget) {
      var selected = this.checkboxesTargets.filter(x => x.checked)
      var unselected = this.checkboxesTargets.filter(x => !x.checked)
      var hidden_value = selected.map(x => x.value).join(',')
      this.hiddenFieldTarget.value = hidden_value
      if (this.data.has('required')) {
        if (selected.length == parseInt(this.data.get('required'))) {
          this.submitTarget.disabled = false
          unselected.forEach(x => x.disabled = true)
        }
        else {
          this.submitTarget.disabled = true
          unselected.forEach(x => x.disabled = false)
        }
      }
    }
  }

  check_for_checked(e) {
    if (this.hasHiddenFieldTarget && this.hiddenFieldTarget.value == '') {
      e.preventDefault()
      console.log(e.target)
      e.target.getElementsByTagName('input')['commit'].disabled = false
      alert('You need to choose at least one email to delete')
    }
  }
}
