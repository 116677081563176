// ./controllers/flatpickr_controller.js
import Flatpickr from "stimulus-flatpickr";
import "flatpickr/dist/themes/light.css";

// creates a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {


    connect(){
        super.connect()        
        let that = this
        let clearBtn = document.createElement('button')
        clearBtn.setAttribute('class', 'btn btn-warning btn-sm my-2')
        clearBtn.innerHTML = 'Clear'
        clearBtn.addEventListener('click', function(){
            that.resetDate()
        })
        let input = this.calendarContainerTarget
        input.appendChild(clearBtn)
    }

    resetDate() {
        this.fp.clear()
        this.fp.close()
    }

}
