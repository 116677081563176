import { Controller } from 'stimulus';
import Swal from 'sweetalert2'

export default class extends Controller {

    connect() {
        console.log(this.element)
    }

    linkClicked(e) {
        var eng = this.element.dataset.eng
        var ref = this.element.dataset.ref
        navigator.clipboard.writeText("https://transcendit.customersure.com/survey/F4nX4pH8FS12aBfzhVuS/completion/new?eng=" + eng + "&reference=" + ref)
        alert("copied link to clipboard")

        e.preventDefault()
    }


}