import { Controller } from "stimulus"

import axios from 'axios'

export default class extends Controller {

  static targets = [ "status",  "mine", "today", "older", "newstatus", "age" ]

  connect(){
    var that=this
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers['Accept'] = 'application/json';
    if(this.statusTarget.classList.contains("active")) {
      this.update()
      this.startTimer() // now for updating how long the status has been unchanged
    }

  }

  disconnect(){
    this.stopTimer()
  }

// showHide is delegated to the sidebartoggler
// showHide(){
  //   if(this.statusTarget.classList.contains("active"))
  //   {
  //     this.statusTarget.classList.remove("active")
  //     this.stopTimer()
  //
  //   }
  //   else
  //   {
  //     this.statusTarget.classList.add("active")
  //     this.updateAges()
  //     this.startTimer()
  //   }
  // }



  update(){
    if (this.statusTarget.classList.contains('active')) {
      var that = this
      axios("/contact_statuses",{
        method: 'GET'
      }).then(function(json){
         that.mineTarget.innerHTML = json.data.mine
         that.todayTarget.innerHTML = json.data.today
         that.olderTarget.innerHTML = json.data.older
      })
    }
  }

  updateAges(){
    let time1 = moment.now();
    this.ageTargets.forEach( (status) => {
      let time2 = moment(status.dataset.created)
      status.innerHTML= moment.duration((time1-time2), "milliseconds").humanize()
    })
  }

  create(e){
    var that = this
    if(e.shiftKey || e.keyCode != 13)
    {
      return
    }
    e.preventDefault()
    e.stopPropagation()
    axios("/contact_statuses",{
      method: 'POST',
      data: {
        contact_status: {
        status: this.newstatusTarget.value,
        contact_id: this.data.get("contactid")
      }
    }
    }).then(function(json){
      let text_field = document.getElementById('status')
      text_field.value = ''
      
      // Reset any error messages if they're present
      if (text_field.classList.contains('is-invalid')) {
        text_field.classList.remove('is-invalid')
        let err_div = text_field.nextElementSibling
        console.log("error div")
        err_div.parentNode.removeChild(err_div)
      }
      
      that.mineTarget.innerHTML = json.data.mystatus
      var li = document.querySelector("ul#my-status li.status");
      setTimeout(function(){
          li.classList.add("updated");
      }, 10);
      setTimeout(function(){
        li.classList.remove("updated");
      }, 1500);
    }).catch(function(error){
      that.newstatusTarget.classList.add('is-invalid')
      let old_errors = document.getElementsByClassName('invalid-feedback')      
      for(let div of old_errors){
        div.remove()
      }
      let err_div = document.createElement('div')
      err_div.classList.add('invalid-feedback')
      err_div.appendChild(document.createTextNode(error.response.data.msg))
      that.newstatusTarget.insertAdjacentElement('afterend', err_div)
    })
  }

  delete(e){
    var that = this
    var target = e.target
    var x = confirm("Are you sure?")
    if(x)
    {
      axios("/contact_statuses/" + e.target.dataset.csId,{
        method: 'DELETE'

      }).then(function(json){

        if( json.data.result == true)
        {
          that.update()
        }
        else
        {

          var div = document.createElement("div")
          div.classList.add("alert", "alert-danger")
          div.id="mynewdiv"
          div.innerHTML=json.data.msg
          target.parentElement.parentElement.parentElement.insertBefore(div, target.parentElement.parentElement.parentElement.firstChild)
           setTimeout(function(){
             document.getElementById("mynewdiv").remove()
           },3000)
        }
      })
    }
  }

  startTimer(){
    var that = this
    that.liveTimer = setInterval(function(){that.updateAges()}, 60000)
  }

  stopTimer(){
    if(this.liveTimer) {
      clearInterval(this.liveTimer)
    }
  }



}
