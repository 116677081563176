import { Controller } from "stimulus"

import axios from 'axios'

export default class extends Controller {

  connect(){
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers['Accept'] = 'application/json';

    console.log('connected unassigned controller');

    this.unassigned()
    this.startTimer()
  }

  disconnect() {
    this.stopTimer()
  }

  unassigned(){
    console.log('axios call /recent_unassigned');
    var noDate = document.getElementById("unassigned-rows")
    if (noDate) {
      var that = this
      axios("/recent_unassigned", {
        method: 'GET',
        params: {
          sorts: that.sortData
        }
      }).then(function(json){
        var noDate = document.getElementById("unassigned-rows")
        var hasDate = document.getElementById("unassigned-due-rows")
        var count = document.getElementById("unassigned_tickets-tab")
        noDate.innerHTML = json.data.unassigned
        hasDate.innerHTML = json.data.unassigned_with_due
        count.innerHTML = json.data.count

        // Set updated at time
        var time = moment().format('DD-MMM-YYYY HH:mm')
        document.querySelector('.last-updated .time').innerText = time;
      })
    }
  }

  setClasses() {
    this.element.querySelectorAll('.sort_link').forEach(a => {
      a.classList.remove('asc')
      a.classList.remove('desc')
    })
    if (this.sortData.due) {
      var dueClass = this.sortData.due
      document.querySelector('.due-table [data-column="'+dueClass.column+'"]').classList.add(dueClass.order)
    }
    if (this.sortData.not_due) {
      var notdueClass = this.sortData.not_due
      document.querySelector('.not-due-table [data-column="'+notdueClass.column+'"]').classList.add(notdueClass.order)
    }
  }

  changeNotDueSort(e) {
    e.preventDefault()
    this.updateSortData('not_due', e.target.dataset.column)
  }

  changeDueSort(e) {
    e.preventDefault()
    this.updateSortData('due', e.target.dataset.column)
  }

  updateSortData(type, column) {
    var data = this.sortData, order

    if (data[type] && data[type]['column'] == column) {
      order = data[type]['order'] == 'asc' ? 'desc' : 'asc'
    }
    else {
      order = 'asc'
    }
    data[type] = {
      column: column,
      order: order
    }

    this.sortData = JSON.stringify(data)
  }

  get sortData() {
    return JSON.parse(this.data.get('sorts'))
  }

  set sortData(sortHash) {
    this.data.set('sorts', sortHash)
    this.setClasses()
    this.unassigned()
  }

  startTimer(){
    var that = this
    that.liveTimer = setInterval(function(){that.unassigned()}, 300000)
  }

  stopTimer(){
    if(this.liveTimer) {
      clearInterval(this.liveTimer)
    }
  }
}
